const GENERAL = 'GENERAL';
const USER_NOT_FOUND = 'USER_NOT_FOUND';
const PASSWORD_LOST_REQUEST_ALREADY_EXISTS = 'PASSWORD_LOST_REQUEST_ALREADY_EXISTS';
const NOT_MATCHING_REQUIREMENTS = 'NOT_MATCHING_REQUIREMENTS';
const PASSWORDS_DO_NOT_MATCH = 'PASSWORDS_DO_NOT_MATCH';
const InvalidToken = 'InvalidToken';
const MAIL_SENT = 'MAIL_SENT';

export default {
  GENERAL,
  USER_NOT_FOUND,
  PASSWORD_LOST_REQUEST_ALREADY_EXISTS,
  NOT_MATCHING_REQUIREMENTS,
  PASSWORDS_DO_NOT_MATCH,
  InvalidToken,
  MAIL_SENT,
};
