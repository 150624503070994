<template>
  <form @submit.prevent="onSubmitNewPassword">
    <div class="control">
      <input
        name="password"
        type="password"
        v-model="formData.password"
        @change="onChange"
        required
      >
      <label for="password">Mot de passe</label>
    </div>
    <div class="control">
      <input
        name="passwordConfirmation"
        type="password"
        v-model="formData.passwordConfirmation"
        @change="onChange"
        required
      >
      <label for="passwordConfirmation">Confirmer le mot de passe</label>
    </div>
    <button
      class="lowco-button"
      type="submit"
    >
      Envoyer
    </button>

    <div
      v-if="isSubmitted && error"
      class="lowco-textbox lowco-textbox-error"
      v-html="getErrorLabel()"
    />
    <div
      v-else-if="isSubmitted"
      class="lowco-textbox lowco-textbox-success"
    >
      Votre mot de passe a bien été changé
    </div>
  </form>
</template>

<script>
import useCurrentCompany from '@/composables/useCurrentCompany';
import routesConstants from '@/constants/routes.constants';
import lowcoApi from '../../api/lowco-api';
import constants from '../../constants/lostPassword.constants';

const capitalRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\W]{6,}$/;

export default {
  name: 'PasswordResetForm',
  props: {
    isEmailConfirmation: Boolean,
  },
  setup() {
    const { clearCompany } = useCurrentCompany();

    return {
      clearCompany,
    };
  },
  data() {
    return {
      formData: {
        email: '',
        password: '',
        passwordConfirmation: '',
      },
      isSubmitted: false,
      error: null,
    };
  },
  methods: {
    onChange(event) {
      const { name, value } = event.target;
      this.formData = {
        ...this.formData,
        [name]: value,
      };
    },
    async onSubmitNewPassword() {
      const { password, passwordConfirmation } = this.formData;

      if (!password || password.length < 6 || !capitalRegex.test(password)) {
        this.isSubmitted = true;
        this.error = constants.NOT_MATCHING_REQUIREMENTS;
        return;
      }

      if (password !== passwordConfirmation) {
        this.isSubmitted = true;
        this.error = constants.PASSWORDS_DO_NOT_MATCH;
        return;
      }

      const userInfo = {
        password: this.formData.password,
        userId: this.$route.query.userId,
        token: this.$route.query.token,
      };

      try {
        if (this.isEmailConfirmation) {
          this.clearCompany();
          
          await lowcoApi.setPassword({ ...userInfo, context: 'BACKOFFICE' });
        } else {
          await lowcoApi.resetPassword(userInfo);
        }

        this.error = null;
        this.formData = {};

        setTimeout(() => {
          let dest = routesConstants.HOME;
          
          if (this.isEmailConfirmation) {
            dest = routesConstants.PROFILE;
          }

          this.$router.push(dest);
        }, 2000);
      } catch (err) {
        const error = err[0];
        this.error = constants[error.code];
      } finally {
        this.isSubmitted = true;
      }
    },
    getErrorLabel() {
      switch (this.error) {
        case constants.NOT_MATCHING_REQUIREMENTS:
          return 'Votre mot de passe doit contenir au moins 6 caractères, une majuscule et un chiffre';
        case constants.PASSWORDS_DO_NOT_MATCH:
          return 'Les deux mots de passe ne sont pas identiques';
        case constants.InvalidToken:
          return 'Le token fourni est invalide';
        default:
          return 'Une erreur générale est survenue, veuillez réssayer plus tard';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/common/variables.scss";
@import "../../assets/styles/common/mixins.scss";

@include centeredLayoutWithLogoForm();
</style>
