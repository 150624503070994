<template>
  <form @submit.prevent="onSubmit">
    <div class="control">
      <input
        name="email"
        type="email"
        @change="onChange"
        required
      >
      <label for="username">Email</label>
    </div>
    <button
      class="lowco-button"
      type="submit"
    >
      Envoyer
    </button>

    <div
      v-if="isSubmitted && error"
      class="lowco-textbox lowco-textbox-error"
      v-html="getErrorLabel()"
    />
    <div
      v-else-if="isSubmitted"
      class="lowco-textbox lowco-textbox-success"
    >
      Un email a été envoyé à l'adresse indiquée
    </div>
  </form>
</template>

<script>
import lowcoApi from '../../api/lowco-api';
import constants from '../../constants/lostPassword.constants';

export default {
  name: 'PasswordLostForm',
  data() {
    return {
      formData: {
        email: '',
        password: '',
        passwordConfirmation: '',
      },
      isSubmitted: false,
      error: null,
    };
  },
  methods: {
    onChange(event) {
      const { name, value } = event.target;
      this.formData = {
        ...this.formData,
        [name]: value,
      };
    },
    async onSubmit() {
      try {
        await lowcoApi.lostPassword(this.formData.email);
        this.error = null;

        setTimeout(() => {
          this.$router.push('/home');
        }, 2000);
      } catch (err) {
        const error = err[0];
        this.error = constants[error.code] || constants.GENERAL;
      }

      this.isSubmitted = true;
    },
    getErrorLabel() {
      switch (this.error) {
        case constants.PASSWORD_LOST_REQUEST_ALREADY_EXISTS:
          return 'Une demande de nouveau mot de passe est déjà en cours pour cet utilisateur';
        default:
          return 'Une erreur générale est survenue, veuillez réssayer plus tard';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/common/variables.scss";
@import "../../assets/styles/common/mixins.scss";

@include centeredLayoutWithLogoForm();
</style>
